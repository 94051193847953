import * as React from "react"
import {graphql} from "gatsby";


import Seo from "../layout/seo";
import Layout from "../layout/layout";
import HeaderImage from "../components/headerImage";
import ResourcesCaregivers from "../components/resources/resourcesCaregivers";

const LayoutTaxCaregivers = ({ data, location }) => {
    return <Layout>
        <HeaderImage node={data.taxonomyTermSclcCaregivers}/>
        <ResourcesCaregivers term={data.taxonomyTermSclcCaregivers}/>
    </Layout>
}

export const Head = ({ data }) => <Seo title={data.taxonomyTermSclcCaregivers.title }
                                       meta_title={data.taxonomyTermSclcCaregivers.field_meta_title}
                                       meta_description={data.taxonomyTermSclcCaregivers.field_meta_description}
/>

export default LayoutTaxCaregivers

export const query = graphql`
  query ($id: String) {
    taxonomyTermSclcCaregivers(id: {eq: $id}) {
      id
      field_meta_description
      field_meta_title
      field_header_text
      field_intro_content {
        value
      }
      title: name
      relationships {
        field_header_image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        field_mobile_header_image {
            localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        field_attached_resources {
          id
          field_resource_type
          field_attached_page_link_text
          field_thumbnail {
            alt
          }
          field_header_image {
            alt
          }
          path {
            alias
          }
          title
          relationships {
              field_list_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            field_thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            
            field_header_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            field_attached_video {
              field_video_id
              relationships {
                field_thumbnail {
                  localFile {
                    publicURL
                  }
                }
              }
            }
            field_attached_page {
              ... on node__page {
                id
                path {
                  alias
                }
              }
              ... on node__resource {
                id
                path {
                  alias
                }
              }
            }
          }
          field_intro_text_2 {
            value
          }
        }
      }
    }
  }
`